import React, { ComponentType, useEffect } from 'react';
import { Field, FieldProps } from '@csas-smart/gti-ui-comps';

/**
 * HOC component, that handles common logic for field validation. Fields thus do not need to repeat the same code for validation for ever.
 * Use when:
 *      - you need the standard validation logic for your field (example: string-field).
 * Do not use when:
 *      - your component is composed of numerous sub components, each with it´s own validation logic.
 *      -> in that case it is better to write your own validation logic into component / create new HOC.
 *
 * @param {REACT component} WrappedComponent component to be subject to validation.
 * @param {JS Function} validationFunction function to be used for validation. Responsible for setting error, must return true/false.
 * optionalValidationParams holds any additional data needed for validation.
 * @param defaultValueError represents components error registration
 */
const validatedComponent = <
    T extends FieldProps & {
        attributes: Record<string, null | undefined | string | object | boolean | number | []>;
    },
>(
    WrappedComponent: ComponentType<T>,
    validationFunction: ValidationFunction,
    defaultValueError: Record<string, unknown> = {},
) => {
    const ValidatedComponent = (props: T) => {
        useEffect(() => {
            const newError = { ...props.validations.validationError, ...defaultValueError };
            props.validations.setError(newError);
        }, []);

        useEffect(() => {
            props.validations.componentMounted(props.field.name, validate);
            // component cleanup
            return function cleanup() {
                props.validations.componentUnmounted(props.field.name);
            };
        }, [...Object.values(props.attributes), props.required]);

        const validate = () => {
            const { validations, required, attributes, field, optionalValidationParams } = props;
            return validationFunction(
                validations.setError,
                required,
                attributes,
                field,
                optionalValidationParams,
            );
        };

        return <WrappedComponent {...props} validate={validate} />;
    };

    ValidatedComponent.displayName = 'ValidatedComponent';
    return ValidatedComponent;
};

export type ValidationFunction = (
    setError: (arg: Record<string, unknown>) => void,
    required: boolean,
    attributes: Record<string, null | undefined | string | object | boolean | number | []>,
    field: Field,
    optionalValidationParams?: Record<string, unknown>,
) => boolean;

export default validatedComponent;
