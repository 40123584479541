import { Language } from '@csas-smart/gti-ui-comps';
import { decodeBase64, encodeBase64 } from '../core/utils/objectUtils';
import { isEmptyObject, isEmptyString, isNotEmptyString } from 'validations';

export const SESSIONS_ID_SESSION_PARAM_NAME = 'sid';
export const SALES_CHANNEL_PARAM = 'salesChannel';
export const MODULE_SESSION_PARAM_NAME = 'module';
export const HASH_ID = 'hashId';
export const GTI_STATE_SESSION_PARAM_NAME = 'gtiState';
export const G_STATE_SESSION_PARAM_NAME = 'gState';
export const ACCESS_TOKEN_SESSION_PARAM_NAME = 'accessToken';
export const ACCESS_TOKEN_EXPIRES_IN_PARAM_NAME = 'accessTokenExpiresIn';
export const REFRESH_ADFS_TOKEN_SESSION_PARAM_NAME = 'refreshAdfsToken';
export const CASETYPE_SESSION_PARAM_NAME = 'casetype';
export const LANG_SESSION_PARAM_NAME = 'lang';
export const CHAT_TYPE_PARAM_NAME = 'moduleId'; //Do not change name of this parameter. External chat-library need it. Chat could not run without it
export const TABLET_NAME = 'isTablet';
export const REDIRECT_URL = 'redirectUrl';
export const LOGIN_CODE_RECEIVED = 'loginCodeReceived';
export const ROUTE_REDIRECT = 'routeRedirect';
export const BTI_TASK = 'btiTask';
export const PRODUCT_NAME = 'gtiProductName';

export const GSTATE_JSON_TYPE = 'json';
export const GSTATE_DEFAULT_TYPE = 'default';
export const ADFS_STARTER_SUPPORT = 'adfsStarterSupport';
export const AUTH_TYPE = 'authType';

class SessionWrapper {
    constructor() {
        console.log('Session wrapper initialized!!!');
    }

    clearSessionStorage = () => {
        window.sessionStorage.removeItem(G_STATE_SESSION_PARAM_NAME);
        window.sessionStorage.removeItem(GTI_STATE_SESSION_PARAM_NAME);
        window.sessionStorage.removeItem(MODULE_SESSION_PARAM_NAME);
        window.sessionStorage.removeItem(ACCESS_TOKEN_SESSION_PARAM_NAME);
        window.sessionStorage.removeItem(REFRESH_ADFS_TOKEN_SESSION_PARAM_NAME);
        window.sessionStorage.removeItem(ACCESS_TOKEN_EXPIRES_IN_PARAM_NAME);
        window.sessionStorage.removeItem(HASH_ID);
        window.sessionStorage.removeItem(SALES_CHANNEL_PARAM);
        window.sessionStorage.removeItem(SESSIONS_ID_SESSION_PARAM_NAME);
        window.sessionStorage.removeItem(TABLET_NAME);
        window.sessionStorage.removeItem(ROUTE_REDIRECT);
        window.sessionStorage.removeItem(LOGIN_CODE_RECEIVED);
        window.sessionStorage.removeItem(REDIRECT_URL);

        window.sessionStorage.removeItem(CHAT_TYPE_PARAM_NAME);
        window.sessionStorage.removeItem(LANG_SESSION_PARAM_NAME);
        window.sessionStorage.removeItem(CASETYPE_SESSION_PARAM_NAME);
        window.sessionStorage.removeItem(PRODUCT_NAME);
        window.sessionStorage.removeItem(BTI_TASK);
        window.sessionStorage.removeItem(ADFS_STARTER_SUPPORT);
        window.sessionStorage.removeItem(AUTH_TYPE);
    };

    setTablet = (isTablet) => {
        window.sessionStorage.setItem(TABLET_NAME, isTablet);
    };

    isTablet = () => {
        return window.sessionStorage.getItem(TABLET_NAME) === 'true';
    };

    setSalesChannel = (salesChannel) => {
        window.sessionStorage.setItem(SALES_CHANNEL_PARAM, salesChannel);
    };

    getSalesChannel = () => {
        return window.sessionStorage.getItem(SALES_CHANNEL_PARAM);
    };

    setGState = (gState) => {
        window.sessionStorage.setItem(G_STATE_SESSION_PARAM_NAME, gState);
    };

    getGState = (type) => {
        const result = this.getGStateInternal();
        switch (type) {
            case GSTATE_DEFAULT_TYPE:
                return result;
            case GSTATE_JSON_TYPE:
                return result ? decodeBase64(result) : null;
            default:
                return result;
        }
    };

    getGStateInternal = () => {
        return window.sessionStorage.getItem(G_STATE_SESSION_PARAM_NAME);
    };

    setTokenStarterSupported = () => {
        window.sessionStorage.setItem(ADFS_STARTER_SUPPORT, 'true');
    };

    supportsAdfsTokenStarter = () => {
        return window.sessionStorage.getItem(ADFS_STARTER_SUPPORT) === 'true';
    };

    setAuthType = (authType) => {
        if (!authType) {
            window.sessionStorage.removeItem(AUTH_TYPE);
        } else {
            window.sessionStorage.setItem(AUTH_TYPE, authType);
        }
    };

    getAuthType = () => {
        return window.sessionStorage.getItem(AUTH_TYPE);
    };

    setAccessToken = (accessToken) => {
        if (!accessToken) {
            window.sessionStorage.removeItem(ACCESS_TOKEN_SESSION_PARAM_NAME);
        } else {
            window.sessionStorage.setItem(ACCESS_TOKEN_SESSION_PARAM_NAME, accessToken);
        }
    };

    clearAccessToken = () => {
        window.sessionStorage.removeItem(ACCESS_TOKEN_SESSION_PARAM_NAME);
    };

    getAccessToken = () => {
        return window.sessionStorage.getItem(ACCESS_TOKEN_SESSION_PARAM_NAME);
    };

    setAccessTokenExpiresIn = (expiresIn) => {
        if (!expiresIn) {
            window.sessionStorage.removeItem(ACCESS_TOKEN_EXPIRES_IN_PARAM_NAME);
        } else {
            console.log('Setting value of token expiration on: ', expiresIn);
            window.sessionStorage.setItem(ACCESS_TOKEN_EXPIRES_IN_PARAM_NAME, expiresIn.getTime());
        }
    };

    getAccessTokenExpiresIn = () => {
        const tokenExpiresInValue = window.sessionStorage.getItem(
            ACCESS_TOKEN_EXPIRES_IN_PARAM_NAME,
        );
        if (!tokenExpiresInValue) {
            return -1;
        }

        if (tokenExpiresInValue === 'null') {
            return -1;
        }

        if (isNaN(Number(tokenExpiresInValue))) {
            return -1;
        }

        return Number(tokenExpiresInValue);
    };

    setRefreshToken = (refreshToken) => {
        if (!refreshToken) {
            window.sessionStorage.removeItem(REFRESH_ADFS_TOKEN_SESSION_PARAM_NAME);
        } else {
            window.sessionStorage.setItem(REFRESH_ADFS_TOKEN_SESSION_PARAM_NAME, refreshToken);
        }
    };

    getRefreshToken = () => {
        return window.sessionStorage.getItem(REFRESH_ADFS_TOKEN_SESSION_PARAM_NAME);
    };

    setGtiState = (gtiState) => {
        window.sessionStorage.setItem(GTI_STATE_SESSION_PARAM_NAME, gtiState);
    };

    getGtiState = () => {
        const result = this.getGtiStateInternal();

        if (isNotEmptyString(result)) {
            return JSON.parse(result);
        } else {
            return null;
        }
    };

    getGtiStateInternal = () => {
        return window.sessionStorage.getItem(GTI_STATE_SESSION_PARAM_NAME);
    };

    setCaseType = (caseType) => {
        window.sessionStorage.setItem(CASETYPE_SESSION_PARAM_NAME, caseType);
    };

    getCaseType = () => {
        return window.sessionStorage.getItem(CASETYPE_SESSION_PARAM_NAME);
    };

    setModule = (module) => {
        window.sessionStorage.setItem(MODULE_SESSION_PARAM_NAME, module);
    };

    getModule = () => {
        return window.sessionStorage.getItem(MODULE_SESSION_PARAM_NAME);
    };

    setHashId = (hashId) => {
        window.sessionStorage.setItem(HASH_ID, hashId);
    };

    getHashId = () => {
        const hashId = window.sessionStorage.getItem(HASH_ID);
        if (!hashId) {
            return null;
        }
        if (this.isEmpty(hashId)) {
            return null;
        }

        return hashId;
    };

    existsHashId = () => {
        return isNotEmptyString(this.getHashId());
    };

    setSessionId = (sessionId) => {
        window.sessionStorage.setItem(SESSIONS_ID_SESSION_PARAM_NAME, sessionId);
    };

    getSessionId = () => {
        return window.sessionStorage.getItem(SESSIONS_ID_SESSION_PARAM_NAME);
    };

    existsSessionId = () => {
        return isNotEmptyString(this.getSessionId());
    };

    setLanguage = (lang: Language) => {
        window.sessionStorage.setItem(LANG_SESSION_PARAM_NAME, lang);
    };

    getLanguage = (): Language => {
        return window.sessionStorage.getItem(LANG_SESSION_PARAM_NAME) as Language;
    };

    /**
     * Some other applications (like Sigma) require language in lower case.
     */
    getLowerCaseLanguage = (): Lowercase<Language> => {
        return this.getLanguage().toLowerCase() as Lowercase<Language>;
    };

    setChatType = (chatType) => {
        window.sessionStorage.setItem(CHAT_TYPE_PARAM_NAME, chatType);
    };

    setRedirect = (redirectUrl) => {
        window.sessionStorage.setItem(REDIRECT_URL, redirectUrl);
    };

    getRedirect = () => {
        const redirectUrl = sessionStorage.getItem(REDIRECT_URL);
        if (isEmptyString(redirectUrl)) {
            return null;
        }

        return redirectUrl;
    };

    setLoginCodeReceived = () => {
        window.sessionStorage.setItem(LOGIN_CODE_RECEIVED, 'true');
    };

    getLoginCodeReceived = () => {
        return window.sessionStorage.getItem(LOGIN_CODE_RECEIVED) === 'true';
    };

    clearLoginCodeReceived = () => {
        window.sessionStorage.removeItem(LOGIN_CODE_RECEIVED);
    };

    setRouteRedirect = (route) => {
        const routeInternal = route ? route : '#/';

        window.sessionStorage.setItem(ROUTE_REDIRECT, routeInternal);
    };

    getRouteRedirect = () => {
        const routeRedirect = window.sessionStorage.getItem(ROUTE_REDIRECT);

        if (this.isEmpty(routeRedirect)) {
            return '#/';
        }

        return routeRedirect;
    };

    setBtiFlag = () => {
        //sessionStorage cannot store boolean value. It stores it in string ...
        window.sessionStorage.setItem(BTI_TASK, 'true');
    };

    isBti = () => {
        return window.sessionStorage.getItem(BTI_TASK) === 'true';
    };

    setProductName = (productName) => {
        window.sessionStorage.setItem(PRODUCT_NAME, productName);
    };

    getProductName = () => {
        const productName = window.sessionStorage.getItem(PRODUCT_NAME);

        if (this.isEmpty(productName)) {
            return null;
        }
        return productName;
    };

    isEmpty = (value) => {
        return !value || value === 'null' || value === 'undefined' || value === '';
    };

    session2state = () => {
        const session = {};
        session[SESSIONS_ID_SESSION_PARAM_NAME] = this.getSessionId();
        session[SALES_CHANNEL_PARAM] = this.getSalesChannel();
        session[MODULE_SESSION_PARAM_NAME] = this.getModule();

        const tmpGtiState = this.getGtiState();
        const gtiState2store = {
            productid: tmpGtiState.productid,
            startedfrom: tmpGtiState.startedfrom,
        };

        session[GTI_STATE_SESSION_PARAM_NAME] = gtiState2store;
        session[G_STATE_SESSION_PARAM_NAME] = this.getGState(null);
        session[CASETYPE_SESSION_PARAM_NAME] = this.getCaseType();
        session[LANG_SESSION_PARAM_NAME] = this.getLanguage();
        session[CHAT_TYPE_PARAM_NAME] = sessionStorage.getItem(CHAT_TYPE_PARAM_NAME);
        session[TABLET_NAME] = this.isTablet();
        session[REDIRECT_URL] = this.getRedirect();
        session[BTI_TASK] = this.isBti();
        session[PRODUCT_NAME] = this.getProductName();
        session[AUTH_TYPE] = this.getAuthType();
        session[ADFS_STARTER_SUPPORT] = this.supportsAdfsTokenStarter();

        return encodeBase64(session);
    };

    state2session = (json) => {
        if (isEmptyObject(json)) {
            return;
        }

        this.setSessionId(json[SESSIONS_ID_SESSION_PARAM_NAME]);
        this.setSalesChannel(json[SALES_CHANNEL_PARAM]);
        this.setModule(json[MODULE_SESSION_PARAM_NAME]);

        const gtiState = json[GTI_STATE_SESSION_PARAM_NAME];
        if (gtiState) {
            if (typeof gtiState === 'string') {
                this.setGtiState(gtiState);
            } else {
                gtiState[CASETYPE_SESSION_PARAM_NAME] = json[CASETYPE_SESSION_PARAM_NAME];
                gtiState[LANG_SESSION_PARAM_NAME] = json[LANG_SESSION_PARAM_NAME];
                gtiState[MODULE_SESSION_PARAM_NAME] = json[MODULE_SESSION_PARAM_NAME];
                gtiState[SALES_CHANNEL_PARAM.toLowerCase()] = json[SALES_CHANNEL_PARAM];

                this.setGtiState(JSON.stringify(gtiState));
            }
        }

        const gState = json[G_STATE_SESSION_PARAM_NAME];
        if (gState) {
            if (typeof gState === 'string') {
                this.setGState(gState);
            } else {
                this.setGState(JSON.stringify(gState));
            }
        }

        this.setCaseType(json[CASETYPE_SESSION_PARAM_NAME]);
        this.setLanguage(json[LANG_SESSION_PARAM_NAME]);
        this.setChatType(json[CHAT_TYPE_PARAM_NAME]);
        this.setTablet(json[TABLET_NAME]);
        this.setRedirect(json[REDIRECT_URL]);
        if (json[BTI_TASK]) {
            this.setBtiFlag();
        }
        if (json[PRODUCT_NAME]) {
            this.setProductName(json[PRODUCT_NAME]);
        }
        if (!this.getAuthType()) {
            if (json[AUTH_TYPE]) {
                this.setAuthType(json[AUTH_TYPE]);
            }
            if (json[ADFS_STARTER_SUPPORT]) {
                this.setTokenStarterSupported();
            }
        }
    };
}

export default SessionWrapper;
