import React from 'react';
import { Button, Card, CardGroup, Col, Row } from '@george-labs.com/design-system';
import IconWrapper from '../core/lazy-wrappers/iconWrapper';
import SessionWrapper from '../sessions/sessionWrapper';
import authService from '../core/auths/authService';

/**
 * Component representing an alert. For additional info please visit: https://gti-library-int.csint.cz/#/fields/alerts-field
 */
const TokenExpired = ({ destroyHnadler }) => {
    const refreshPageHandler = () => {
        const sessionWrapper = new SessionWrapper();
        sessionWrapper.setAccessTokenExpiresIn(null);
        sessionWrapper.clearAccessToken();
        location.reload();
    };

    const logout = () => {
        destroyHnadler();
        authService.getService().logout();
    };

    return (
        <CardGroup>
            <Card className={'card g-card'}>
                <Row>
                    <Col sm={12} className={'d-flex justify-content-center mb-4'}>
                        <IconWrapper
                            name="error-circle-xl"
                            className={'svg-container'}
                            style={{ color: 'red' }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className={'d-flex justify-content-center mb-2'}>
                        <h2>Odhlásili jsme vás</h2>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className={'d-flex justify-content-center'}>
                        Můžete pokračovat pomocí tlačítka Přihlásit znova nebo se vrátit zpět do
                        Servisní zóny.
                    </Col>
                </Row>
            </Card>
            <Card className={'card g-card'}>
                <Row>
                    <Col sm={12} className={'d-flex justify-content-center'}>
                        <Button
                            variant={Button.VARIANT.SECONDARY}
                            className={'m-1'}
                            onClick={logout}
                        >
                            Zpět do servisní zóny
                        </Button>
                        <Button
                            variant={Button.VARIANT.PRIMARY}
                            className={'m-1'}
                            onClick={refreshPageHandler}
                        >
                            Přihlásit znova
                        </Button>
                    </Col>
                </Row>
            </Card>
        </CardGroup>
    );
};

export default TokenExpired;
