import Resource from '../core/serverresource';
import RestCaller from '../core/restCaller';
import authService from '../core/auths/authService';

export const fireHandoverToken = () => {
    return () => {
        authService.getService().handover();
    };
};

export const sessionLogin = (cgpHashedId) => {
    return () => {
        return RestCaller.httpPostWithStatusCheck(Resource.sessionLogin(cgpHashedId), null).then(
            (response) => Promise.resolve(response.text()),
        );
    };
};

export const tokenExpiring = () => ({ type: 'TOKEN_EXPIRING' });
