import {
    SigmaBranchSignatureFlowOverviewFieldProps,
    SigmaBranchSignatureOverviewField,
} from '@csas-smart/gti-sigma-ui';
import React from 'react';
import SessionWrapper from '../../sessions/sessionWrapper';
import { FieldProps } from '@csas-smart/gti-ui-comps/dist/types/types';

export interface SigmaDocumentListPreviewFieldProps extends FieldProps {
    attributes: SigmaBranchSignatureFlowOverviewFieldProps['attributes'];
}

const SigmaBranchSignatureOverviewFieldContainer = (props: SigmaDocumentListPreviewFieldProps) => {
    const sessionWrapper = new SessionWrapper();

    const folderApiConfig = {
        environment: import.meta.env.VITE_ENV.toUpperCase(),
        webApiKey: import.meta.env.VITE_APP_API_KEY,
        accessToken: sessionWrapper.getAccessToken(),
    };

    const authApiConfig = {
        environment: import.meta.env.VITE_ENV.toUpperCase(),
        webApiKey: import.meta.env.VITE_APP_API_KEY,
        onlineAuthorizationToken: sessionWrapper.getAccessToken(), // is this correct?
    };

    return (
        <SigmaBranchSignatureOverviewField
            {...props}
            folderApiConfig={folderApiConfig}
            authApiConfig={authApiConfig}
            lang={sessionWrapper.getLowerCaseLanguage()}
        />
    );
};

export default SigmaBranchSignatureOverviewFieldContainer;
