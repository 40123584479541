import SessionWrapper from '../../sessions/sessionWrapper';
import { getLocale } from '../i18n';

/**
 * enhancer sets the 'Accept-Language' header to resolved lang in i18n through the 'getLocale' method
 */
class AcceptLanguage {
    HEADER_NAME = 'Accept-Language';

    DEFAULT_LANG_VALUE = 'cs';

    sessionWrapper: SessionWrapper;

    constructor() {
        this.sessionWrapper = new SessionWrapper();
    }

    enhanceHeaders(headers) {
        if (!headers) {
            headers = {};
        }

        const lang = getLocale();

        return { ...headers, [this.HEADER_NAME]: lang ? lang : this.DEFAULT_LANG_VALUE };
    }
}

export default AcceptLanguage;
