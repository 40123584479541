import { UserState } from './types';

const initialState: UserState = { actor: null };

// eslint-disable-next-line @typescript-eslint/default-param-last
const userReducers = (state = initialState, action): UserState => {
    let newState;
    switch (action?.type) {
        case 'ACTOR_LOADED':
            newState = { ...state, actor: action.actor };
            return newState;

        default:
            return state;
    }
};

export default userReducers;
