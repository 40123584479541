import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { resolveHashIdFromState } from '../core/utils/taskUtils';
import RestCaller from '../core/restCaller';
import Resource from '../core/serverresource';
import { Button } from '@george-labs.com/design-system';
import { isMobileApp } from '../core/utils/salesChannelUtils';
import PropertiesLoader from '../core/propertiesloader';
import { isNotEmptyString } from 'validations';

const SignButton = (props) => {
    const { completeActivityAction, option } = props;

    const [customAuthorizationOriginApplication, setCustomAuthorizationOriginApplication] =
        useState(false);
    const [authorizationHeaders, setAuthorizationHeaders] = useState({});

    /**
     * useEffect for loading custom authorizationOriginApplication from field editor properties and setting up:
     * customAuthorizationOriginApplication flag and authorizationHeaders if needed
     */
    useEffect(() => {
        const authorizationSettings = PropertiesLoader.getProperty(
            props.field.editor,
            'authorization',
        );
        const toBeCustomAuthorizationOriginApplication =
            authorizationSettings && authorizationSettings.authorizationOriginApplication;
        setCustomAuthorizationOriginApplication(toBeCustomAuthorizationOriginApplication);
        if (toBeCustomAuthorizationOriginApplication) {
            const toBeAuthorizationHeaders = {};
            toBeAuthorizationHeaders['origin-application-system'] =
                authorizationSettings.authorizationOriginApplication;
            toBeAuthorizationHeaders['application-system'] =
                authorizationSettings.authorizationOriginApplication;
            setAuthorizationHeaders(toBeAuthorizationHeaders);
        }
    }, []);

    const authIdAttributeName = props.field.selector.attributes.authId.name;

    // For applications run within the webview, the sign URL retrived from auth module must be enhanced by the following suffix: https://cnfl.csin.cz/pages/viewpage.action?pageId=1037238762
    const APP_AUTH_SUFFIX = '&mobileDevice=1';

    const [authId, setAuthId] = useState(null);

    //signUrl is returned on response of calling sign auths operation
    //signUrl will be used in iFrame
    const [signUrl, setSignUrl] = useState(null);

    //pollUri is used for polling state of authorized operation
    //pollUri is returned by calling sign auths operation
    const [pollUri, setPollUri] = useState(null);
    const [pollInterval, setPollInterval] = useState(0);

    //param is set if and only if SIGN polling result is received
    const [signed, setSigned] = useState(false);

    let pollTimeout = null;

    const hashId = resolveHashIdFromState();

    /**
     * This useEffect handle change of 'pollUri' and 'pollInterval' state-properties. If they are both set to valid values
     * (pollUri is something like '/v1/smart/...' and pollInterval > 0) then polling-state-timeout will be set and check
     * authorization state.
     *
     * If both properties are cleared then timeout is cleared (for non-cycling polling calls)
     */
    useEffect(() => {
        if (pollUri && pollInterval > 0) {
            setPollTimeout();
        } else if (!pollUri && pollInterval === 0) {
            clearTimeout(pollTimeout);
        }
    }, [pollUri, pollInterval]);

    /**
     * This useEffect handle change of 'signed' state-property. If it change to true then call completeActivity.
     * Otherwise 'just do nothing'
     */
    useEffect(() => {
        if (signed) {
            if (props.onClick) {
                props
                    .fieldValueChanged({ name: authIdAttributeName, value: authId })
                    //close popup after case is signed
                    .then(() => setSignUrl(null));

                const evt = {
                    target: { value: option.key, getAttribute: () => option.buttonType },
                };
                props.onClick(evt);
            } else {
                props
                    .fieldValueChanged({ name: authIdAttributeName, value: authId })
                    .then(() => completeActivityAction())
                    //close popup after case is signed
                    .then(() => setSignUrl(null));
            }
        }
    }, [signed]);

    /**
     * handler is executed on timeout event occured. It checks authorization state and if it is SIGNED then
     * * stop timeout handler
     * * close signing popup
     * * complete current activity
     */
    const callPoll = () => {
        //TODO - az bude znam vystupni objekt, zvalidovat stav auth operace a v pripade SIGNED
        RestCaller.httpGet(Resource.pollUrl(pollUri), authorizationHeaders).then((result) => {
            //state = WAITING_FOR_SIGN
            //state = PROCESSING
            //state = EXPIRED
            //state = REJECTED
            //signed ??? Fnuk ...

            if (result && result.state == 'DONE') {
                //for correct stop of timeout handling (usage of useEffect [pollUri, pollInterval])
                setPollInterval(0);
                setPollUri(null);

                //for correct complete activity (usage of useEffect [signed])
                setSigned(true);
            } else if (result && result.state == 'REJECTED') {
                RestCaller.httpDelete(Resource.cancelAuth(authId, hashId)).then(() => {
                    closePopupInternal();
                });
            } else {
                resetTimeout();
            }
        });
    };

    const setPollTimeout = () => {
        if (pollInterval <= 0) {
            console.error('Zero or negated value of interval: ' + pollInterval);
            return;
        }

        pollTimeout = setTimeout(callPoll, pollInterval);
    };

    const resetTimeout = () => {
        clearTimeout(pollTimeout);
        setPollTimeout();
    };

    const callSignCase = () => {
        const valid = props.validate();
        console.log('Valid: ' + valid);
        if (!valid) {
            return;
        }
        RestCaller.httpPost(Resource.createAuth(hashId), null).then((result) => {
            //please do not use destruct form - hashId name was not good choice :)
            const theAuthId = result.id;
            setAuthId(theAuthId);

            RestCaller.httpGet(
                Resource.signAuth(theAuthId, result.hashId, customAuthorizationOriginApplication),
                authorizationHeaders,
            ).then((outcome) => {
                let urlForSign = outcome.signingUrl;
                if (isMobileApp()) {
                    console.log('Extending URL to MEP');
                    urlForSign += APP_AUTH_SUFFIX;
                }
                setSignUrl(urlForSign);

                //Z nejakeho duvodu se na responsi vraci url se znakem ':' na konci
                const pollUriValue = outcome.poll.url.endsWith(':')
                    ? outcome.poll.url.substring(0, outcome.poll.url.length - 1)
                    : outcome.poll.url;
                setPollUri(pollUriValue);

                const newPollInterval = isNaN(outcome.poll.interval) ? 5000 : outcome.poll.interval;
                setPollInterval(newPollInterval);
            });
        });
    };

    const closePopupInternal = () => {
        //for correct stop of timeout handling (usage of useEffect [pollUri, pollInterval])
        setPollInterval(0);
        setPollUri(null);

        //for correct close sign popup
        setSignUrl(null);
    };

    return (
        <>
            <Button
                buttonType={option.buttonType}
                onClick={callSignCase}
                variant={Button.VARIANT.PRIMARY}
                value={option.key}
                disabled={props.disabled}
            >
                {option.label}
            </Button>

            <Modal
                isOpen={isNotEmptyString(signUrl)}
                backdrop={'static'}
                wrapClassName="g-bs4 g-bootstrap g-store fontsize14"
                modalClassName="g-modal"
                size={'xl'}
            >
                <ModalBody className="g-modal-body-full justify-content-center">
                    <iframe src={signUrl} className={'signIFrame'}></iframe>
                </ModalBody>
            </Modal>
        </>
    );
};

export default SignButton;
