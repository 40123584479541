import { CategorizationState } from './types';

const initialState: CategorizationState = {
    categories: null,
    selectedCaseType: null,
    selectedSubCaseType: null,
};

function mapCategory(category) {
    if (category.subCaseTypes && category.subCaseTypes.length > 0) {
        //split "subCaseTypes.length" times
        const splitted = category.subCaseTypes.map((subCaseType) =>
            Object.assign({}, category, { subCaseTypes: [], subCaseType: subCaseType }),
        );
        return splitted;
    } else {
        //just return category
        return category;
    }
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const categorizationReducers = (state = initialState, action): CategorizationState => {
    let newState;
    switch (action.type) {
        case 'UPDATE_CATEGORIES': {
            const categories = action.categories.flatMap((category) => mapCategory(category));
            //var categories  = action.categories;
            newState = Object.assign({}, initialState, {
                categories: categories,
                selectedCaseType: null,
            });
            return newState;
        }
        case 'SELECT_CATEGORY': {
            const caseType = action.caseType;
            const subCaseType = action.subCaseType;

            newState = Object.assign({}, initialState, {
                categories: [...state.categories],
                selectedCaseType: caseType,
                selectedSubCaseType: subCaseType,
            });
            return newState;
        }
        default:
            return state;
    }
};

export default categorizationReducers;
