import promisedHandlebars from 'promised-handlebars';
import PureHandlebars from 'handlebars';
import dayjs from 'dayjs';
import Resource from './serverresource';
import RestCaller from './restCaller';
import { addLabourDays } from '../common/reusableFunctions';
import en from '../locales/en/default.json';
import cs from '../locales/cs-CZ/default.json';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const jexl = require('jexl');
const Handlebars = promisedHandlebars(PureHandlebars, { Promise });

export const getHandlebars = () => {
    return Handlebars;
};

// Function takes input date in ms and amount of days to add. Function formats output to human readable form, because it is not possible to combine multiple functions.
Handlebars.registerHelper('addLabourDays', function (initialDate, duration) {
    if (initialDate === 'now') {
        initialDate = new Date().getTime();
    }

    return addLabourDays(initialDate, duration).then((result: any) => {
        if (dayjs) {
            return dayjs(result.resultDate).format('DD. MM. YYYY');
        } else return result.resultDate;
    });
});
Handlebars.registerHelper('dateAdd', function (originalDate, datePart, value, stayInUltimo) {
    if (!originalDate || !datePart || !value) {
        return null;
    }
    const d = new Date(+originalDate);
    const originalMonth = d.getMonth();
    if (datePart === 'y') {
        d.setFullYear(d.getFullYear() + value);
    } else if (datePart === 'm') {
        d.setMonth(d.getMonth() + value);

        /**
         * Helps to fix edge cases (eg. 31.5. - 1 month === 1.5. in JS)
         */
        const resultingMonthChecker = (origMonth, valueToAdd) => {
            let resultMonth = origMonth;
            if (valueToAdd > 0) {
                for (let i = 1; i <= valueToAdd; i++) {
                    resultMonth = resultMonth + 1;
                    if (resultMonth === 12) {
                        resultMonth = 0;
                    }
                }
            } else {
                for (let i = valueToAdd; i <= -1; i++) {
                    resultMonth = resultMonth - 1;
                    if (resultMonth === -1) {
                        resultMonth = 11;
                    }
                }
            }
            return resultMonth;
        };

        /**
         * E.g. converts edge cases (eg. 1.5. to 30.04.)
         */
        if (d.getMonth() !== resultingMonthChecker(originalMonth, value)) {
            d.setDate(0);
        }
    } else if (datePart === 'd') {
        d.setDate(d.getDate() + value);
    }
    if (stayInUltimo && (datePart === 'y' || datePart === 'm')) {
        if (originalMonth === d.getMonth()) {
            d.setMonth(d.getMonth() + 1);
        }
        d.setDate(0);
    }
    return d.getTime();
});

Handlebars.registerHelper('toUpperCase', function (name) {
    return name.toUpperCase();
});

Handlebars.registerHelper('codebookAttributeValue', function (key, cbName, attributeName) {
    //console.log("atts: " + attributeName);
    //cbName = "CB_Bullshit";``
    console.info(
        'Calling codebookAttributeValue [key: ' +
            key +
            ', cbName:' +
            cbName +
            ', attributeName: ' +
            attributeName +
            ']',
    );
    if (!attributeName) {
        console.error('Missing attribute name in codebookAttributeValue');
        return '';
    }

    return RestCaller.httpPostWithLang(
        Resource.getCodebookValue(cbName, key),
        [attributeName],
        'cs',
    )
        .then((codebookEntry) => codebookEntry.attributes[attributeName])
        .catch((error) => {
            console.error(
                'Error during CodebookAtributteValue call for cbName: ' +
                    cbName +
                    ' and key: ' +
                    key +
                    ' and attribute: ' +
                    attributeName +
                    '. Message: ' +
                    error,
            );
            return key;
        });
});

Handlebars.registerHelper('codebookValue', function (key, cbName) {
    return RestCaller.httpPostWithLang(Resource.getCodebookValue(cbName, key), [], 'cs')
        .then((codebookEntry) => codebookEntry.value)
        .catch((error) => {
            console.error(
                'Error during codebookValue call for cbName: ' +
                    cbName +
                    ' and key: ' +
                    key +
                    '. Message: ' +
                    error,
            );
            return key;
        });
});

Handlebars.registerHelper('yf', function (expression, options) {
    const data = { ...options.data.root };

    data.item = this;
    const result = jexl.evalSync(expression, data);

    return result === true ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper('formatDate', function (dateMs, format) {
    console.log(format);
    console.log(dateMs);
    if (dayjs) {
        return dayjs(dateMs).format(format);
    } else {
        return dateMs;
    }
});

Handlebars.registerHelper('addSpaces', function (num) {
    if (num) {
        return num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ');
    } else {
        return num;
    }
});

Handlebars.registerHelper('addNonBreakingSpaces', function (num) {
    if (num) {
        return num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1\u00A0');
    } else {
        return num;
    }
});

// Helper translates date into form with string in correct language mutation.
Handlebars.registerHelper('printDate', function (dateInMs, lang, withDay) {
    const current = new Date(dateInMs);
    const translation = lang === 'cs' ? cs : en;
    const month = translation['phrases.months.' + (current.getMonth() + 1)];
    const day = withDay ? current.getDate() + '. ' : '';
    return day + month + ' ' + current.getFullYear();
});

Handlebars.registerHelper('removePrefix', function (fullAccountNumber) {
    const prefixPosition = fullAccountNumber?.indexOf('-');
    if (prefixPosition > 0) {
        return fullAccountNumber.substring(prefixPosition + 1, fullAccountNumber.length);
    } else {
        return fullAccountNumber;
    }
});

Handlebars.registerHelper('removePrefixZeros', function (fullAccountNumber) {
    return fullAccountNumber.replace(/^0+/, '');
});

Handlebars.registerHelper('removeLeadZerosFromAccountNumber', function (fullAccountNumber) {
    let result = '';
    if (fullAccountNumber != null && fullAccountNumber != undefined) {
        const splitedAccouncNumber = fullAccountNumber.split('-');
        splitedAccouncNumber.forEach(function (item) {
            result =
                result + (String(result).length === 0 ? '' : '-') + String(item).replace(/^0+/, '');
        });
        result = result.replace('-', '');
        return result;
    } else {
        return result;
    }
});

Handlebars.registerHelper('trimString', function (passedString, startIndex, endIndex) {
    return passedString.substring(startIndex, endIndex);
});

Handlebars.registerHelper('replacePlaceholder', function (key) {
    const placeholders = [];

    //TODO - toto neni pekne
    placeholders.push({
        name: 'feedback.url',
        value: import.meta.env.VITE_APP_FEEDBACK_URL,
    });
    placeholders.push({
        name: 'servicing.base.url',
        value: import.meta.env.VITE_APP_BASE_URL,
    });
    placeholders.push({
        name: 'dispo.url',
        value: import.meta.env.VITE_APP_DISPO_URL,
    });
    placeholders.push({
        name: 'cdn.url',
        value: import.meta.env.VITE_APP_CDN_URL,
    });
    placeholders.push({
        name: 'george.url',
        value: import.meta.env.VITE_APP_RKT_URL,
    });
    placeholders.push({
        name: 'mep.url',
        value: import.meta.env.VITE_APP_MEP_URL,
    });
    placeholders.push({
        name: 'storeVirtualCard.url',
        value: import.meta.env.VITE_APP_RKT_STORE_VIRTUAL_CARD_URL,
    });
    placeholders.push({
        name: 'storeDebitCard.url',
        value: import.meta.env.VITE_APP_RKT_STORE_DEBIT_CARD_URL,
    });
    placeholders.push({
        name: 'g4bmigr.url',
        value: import.meta.env.VITE_APP_G4BMIGR_RESOURCE_URL,
    });
    placeholders.push({
        name: 'crmApp.url',
        value: import.meta.env.REACT_APP_CRM_URL,
    });
    placeholders.push({
        name: 'crmPathApp.url',
        value: import.meta.env.REACT_PATH_APP_CRM_URL,
    });
    placeholders.push({
        name: 'enviroment.name',
        value: import.meta.env.VITE_ENV,
    });

    const items = placeholders.filter((item) => item.name === key);

    if (items && items.length > 0) {
        return items[0].value;
    }

    return key;
});

/**
 * Throws a TypeError if either a or b is not a number or something that can be converted to a number.
 * @param {*} a
 * @param {*} b
 * @param {String} helperName
 * @param {Boolean} strictMode ensures only number or a non-empty string that can be converted to a number can be used.
 */
function ensureBothParametersAreNumbers(a, b, helperName, strictMode = true) {
    if (strictMode) {
        // Strict mode: Allow only numbers or non-empty strings that can be converted to numbers
        if (
            typeof a !== 'number' &&
            (typeof a !== 'string' || a.trim() === '' || isNaN(Number(a)))
        ) {
            throw new TypeError(
                `Handlebars Helper "${helperName}" (Strict Mode): Expected the first argument to be a number or a non-empty string that can be converted to a number. Received: "${a}"`,
            );
        }
        if (
            typeof b !== 'number' &&
            (typeof b !== 'string' || b.trim() === '' || isNaN(Number(b)))
        ) {
            throw new TypeError(
                `Handlebars Helper "${helperName}" (Strict Mode): Expected the second argument to be a number or a non-empty string that can be converted to a number. Received: "${b}"`,
            );
        }
    } else {
        // Default mode: Original behavior
        if (isNaN(a)) {
            throw new TypeError(
                `Handlebars Helper "${helperName}": Expected the first argument to be a number or something that can be converted to a number. Received: "${a}"`,
            );
        }
        if (isNaN(b)) {
            throw new TypeError(
                `Handlebars Helper "${helperName}": Expected the second argument to be a number or something that can be converted to a number. Received: "${b}"`,
            );
        }
    }
}

/**
 * Return the addition of `a` plus `b`.<br />
 * Accepts any not-null values that can be safely converted to number
 * @param {Number} `a`
 * @param {Number} `b`
 * @example
 * HBTS('{{add numAttr1 numAttr2}}')
 * HBTS('{{add "5" 1}}')
 */
Handlebars.registerHelper('add', function (a, b) {
    ensureBothParametersAreNumbers(a, b, 'add');

    // Convert to numbers to ensure accurate calculation
    return Number(a) + Number(b);
});

/**
 * Return the difference of `a` minus `b`.<br />
 * Accepts any values that can be converted to number
 * @param {Number} `a`
 * @param {Number} `b`
 * @example
 * HBTS('{{subtract numAttr1 numAttr2}}')
 * HBTS('{{subtract "5" 1}}')
 */
Handlebars.registerHelper('subtract', function (a, b) {
    ensureBothParametersAreNumbers(a, b, 'subtract', false);

    // Convert to numbers to ensure accurate calculation
    return Number(a) - Number(b);
});

/**
 * Return the multiplication of `a` * `b`.<br />
 * Accepts any values that can be converted to number
 * @param {Number} `a`
 * @param {Number} `b`
 * @example
 * HBTS('{{multiply numAttr1 numAttr2}}')
 * HBTS('{{multiply "5" 1}}')
 */
Handlebars.registerHelper('multiply', function (a, b) {
    ensureBothParametersAreNumbers(a, b, 'multiply', false);

    // Convert to numbers to ensure accurate calculation
    return Number(a) * Number(b);
});

/**
 * Return the division of `a` over `b`.<br />
 * Accepts any not-null values that can be safely converted to number
 * @param {Number} `a`
 * @param {Number} `b`
 * @example
 * HBTS('{{divide numAttr1 numAttr2}}')
 * HBTS('{{divide "5" 1}}')
 */
Handlebars.registerHelper('divide', function (a, b) {
    ensureBothParametersAreNumbers(a, b, 'divide');

    // Convert to numbers to ensure accurate calculation
    return Number(a) / Number(b);
});

/**
 * The round function syntax has the following arguments:
 *
 * @param {Number} `a` The number that you want to round.
 * @param {Number} `b` The number of digits to which you want to round the number argument.
 */
Handlebars.registerHelper('round', function (a, b) {
    ensureBothParametersAreNumbers(a, b, 'round');
    return Number(a).toFixed(Number(b));
});

/**
 * Fake handlebar helper! The reason for this is, that the outcome of handlebar template is HTML code, wherease for
 * the static files I need to return a fully functional React component with handlers and JS code. The content of
 * this handler is replaced in the rerender phase of the template action.
 *
 * @param {string} `id` the artificial-id of the document to be displayed.
 * @param {string} `label` label of the link element to displayed.
 */
Handlebars.registerHelper('linkToFile', function (id, label) {
    return '{{linkToFile ' + id + ' ' + label + '}}';
});

/**
 * Replace all instances of a "." char to "," char
 * @param text Original string
 */
Handlebars.registerHelper('replaceAllDots', function (text) {
    if (isNaN(text)) {
        throw new TypeError('expected argument to be a string');
    }
    return String(text).replaceAll(String('.'), String(','));
});

/**
 * Unescapes forbidden characters
 * @param text Original string
 */

Handlebars.registerHelper('encodeString', function (text) {
    return new Handlebars.SafeString(text);
});

/**
 * Checks if two values are equal.
 * @param {any} a - First value.
 * @param {any} b - Second value.
 * @example {{eq 5 5}} => true
 */
Handlebars.registerHelper('eq', function (a, b) {
    return a === b;
});

/**
 * Checks if two values are not equal.
 * @param {any} a - First value.
 * @param {any} b - Second value.
 * @example {{neq 5 6}} => true
 */
Handlebars.registerHelper('neq', function (a, b) {
    return a !== b;
});

/**
 * Conditional helper to check if two values are equal.
 * @param {any} a - First value.
 * @param {any} b - Second value.
 * @param {object} opts - Handlebars options object.
 * @example {{#if_eq 5 5}} They are equal. {{else}} They are not equal. {{/if_eq}}
 */
Handlebars.registerHelper('if_eq', function (a, b, opts) {
    if (a === b) {
        return opts.fn(this);
    } else {
        return opts.inverse(this);
    }
});

/**
 * Checks if the first value is greater than the second value.
 * @param {any} a - First value.
 * @param {any} b - Second value.
 * @example {{gt 10 5}} => true
 */
Handlebars.registerHelper('gt', function (a, b) {
    return a > b;
});

/**
 * Checks if the first value is less than the second value.
 * @param {any} a - First value.
 * @param {any} b - Second value.
 * @example {{lt 5 10}} => true
 */
Handlebars.registerHelper('lt', function (a, b) {
    return a < b;
});

/**
 * Checks if the first value is greater than or equal to the second value.
 * @param {any} a - First value.
 * @param {any} b - Second value.
 * @example {{gte 10 10}} => true
 */
Handlebars.registerHelper('gte', function (a, b) {
    return a >= b;
});

/**
 * Checks if the first value is less than or equal to the second value.
 * @param {any} a - First value.
 * @param {any} b - Second value.
 * @example {{lte 5 10}} => true
 */
Handlebars.registerHelper('lte', function (a, b) {
    return a <= b;
});

/**
 * Logical AND operation.
 * @param {any} a - First value.
 * @param {any} b - Second value.
 * @example {{and true false}} => false
 */
Handlebars.registerHelper('and', function (a, b) {
    return a && b;
});

/**
 * Logical OR operation.
 * @param {any} a - First value.
 * @param {any} b - Second value.
 * @example {{or true false}} => true
 */
Handlebars.registerHelper('or', function (a, b) {
    return a || b;
});

/**
 * Logical NOT operation.
 * @param {any} a - Value.
 * @example {{not true}} => false
 */
Handlebars.registerHelper('not', function (a) {
    return !a;
});

/**
 * Conditional helper to check if a number is even.
 * @param {number} a - Number to check.
 * @param {object} opts - Handlebars options object.
 * @example {{#if_even 4}} Number is even. {{else}} Number is odd. {{/if_even}}
 */
Handlebars.registerHelper('if_even', function (a, opts) {
    if (a % 2 === 0) {
        return opts.fn(this);
    } else {
        return opts.inverse(this);
    }
});

/**
 * Conditional helper to check if a number is odd.
 * @param {number} a - Number to check.
 * @param {object} opts - Handlebars options object.
 * @example {{#if_odd 3}} Number is odd. {{else}} Number is even. {{/if_odd}}
 */
Handlebars.registerHelper('if_odd', function (a, opts) {
    if (a % 2 !== 0) {
        return opts.fn(this);
    } else {
        return opts.inverse(this);
    }
});

/**
 * Converts a JavaScript object to a JSON string.
 * @param {object} context - Object to stringify.
 * @example {{json myObject}} => '{"key":"value"}'
 */
Handlebars.registerHelper('json', function (context) {
    return JSON.stringify(context);
});

/**
 * Converts a string to lowercase.
 * @param {string} str - String to convert.
 * @example {{lowercase "HELLO"}} => "hello"
 */
Handlebars.registerHelper('lowercase', function (str) {
    return str.toLowerCase();
});

/**
 * Capitalizes the first letter of a string.
 * @param {string} str - String to capitalize.
 * @example {{capitalize "hello"}} => "Hello"
 */
Handlebars.registerHelper('capitalize', function (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
});

/**
 * Capitalizes the first letter of each word in a string.
 * @param {string} str - String to capitalize.
 * @example {{capitalizeWords "hello world"}} => "Hello World"
 */
Handlebars.registerHelper('capitalizeWords', function (str) {
    return str.replace(/\b\w/g, function (char) {
        return char.toUpperCase();
    });
});

/**
 * Truncates a string to a specified length and adds ellipses.
 * @param {string} str - String to truncate.
 * @param {number} len - Maximum length of the truncated string.
 * @example {{truncate "This is a long string" 10}} => "This is a..."
 */
Handlebars.registerHelper('truncate', function (str, len) {
    if (str.length > len) {
        return str.substring(0, len) + '...';
    }
    return str;
});

/**
 * Replaces all occurrences of a substring within a string.
 * @param {string} str - Original string.
 * @param {string} find - Substring to find.
 * @param {string} replace - Substring to replace with.
 * @example {{replace "Hello World" "World" "Handlebars"}} => "Hello Handlebars"
 */
Handlebars.registerHelper('replace', function (str, find, replace) {
    return str.split(find).join(replace);
});

/**
 * Checks if a string starts with a specific prefix.
 * @param {string} str - String to check.
 * @param {string} prefix - Prefix to check.
 * @example {{#if (startsWith "Hello World" "Hello")}} Starts with Hello. {{/if}}
 */
Handlebars.registerHelper('startsWith', function (str, prefix) {
    return str.startsWith(prefix);
});

/**
 * Joins a list of items into a string with a specified separator.
 * @param {Array} context - List of items.
 * @param {string} separator - Separator to use.
 * @example {{join items ", "}} => "item1, item2, item3"
 */
Handlebars.registerHelper('join', function (context, separator) {
    return context.join(separator);
});

/**
 * Filters a list of objects based on a specific property value.
 * @param {Array} context - List of objects.
 * @param {string} property - Property to filter by.
 * @param {any} value - Value to filter by.
 * @param {object} options - Handlebars options object.
 * @example {{#filter items "type" "fruit"}} {{name}} {{/filter}}
 */
Handlebars.registerHelper('filter', function (context, property, value, options) {
    const filtered = context.filter((item) => item[property] === value);
    return filtered.map((item) => options.fn(item)).join('');
});

/**
 * Sorts a list of objects based on a specific property.
 * @param {Array} context - List of objects.
 * @param {string} property - Property to sort by.
 * @param {object} options - Handlebars options object.
 * @example {{#sort items "name"}} {{name}} {{/sort}}
 */
Handlebars.registerHelper('sort', function (context, property, options) {
    const sorted = context.slice().sort((a, b) => (a[property] > b[property] ? 1 : -1));
    return sorted.map((item) => options.fn(item)).join('');
});

/**
 * Checks if an element is in a list of objects by comparing a specific property.
 * @param {object} elem - Element to check.
 * @param {Array} list - List of objects.
 * @param {string} property - Property to compare.
 * @example {{#if (isIn item list "id")}} Item is in the list. {{/if}}
 */
Handlebars.registerHelper('isIn', function (elem, list, property) {
    return list.some(function (item) {
        return item[property] === elem[property];
    });
});

/**
 * Checks if a value is in a list.
 * @param {any} value - Value to check.
 * @param {Array} list - List to check in.
 * @example {{#if (includes "apple" fruits)}} Apple is in the list. {{/if}}
 */
Handlebars.registerHelper('includes', function (value, list) {
    return list.includes(value);
});

const HandlebarUtil = () => {
    return null;
};

export default HandlebarUtil;
