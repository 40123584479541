import Resource from '../core/serverresource';
import RestCaller from '../core/restCaller';
import { fireAttrChange } from './taskActions';
import { resolveHashIdFromState } from '../core/utils/taskUtils';
import { isEmptyArray } from 'validations';

const documentDeleted = (attrName, documentId) => ({
    attrName: attrName,
    type: 'DOCUMENT_DELETED',
    documentId,
});

export function loadDocument(fieldName, document) {
    return () => {
        const hashId = resolveHashIdFromState();
        const url = Resource.getDocument(fieldName, hashId, document);

        return RestCaller.httpGetWithBinary(url).then((response) => {
            const contentDispositionHeaderMatch = response.headers
                .get('Content-Disposition')
                .match(/filename="([^"]+)"/);
            const contentDispositionHeader = contentDispositionHeaderMatch
                ? contentDispositionHeaderMatch[1]
                : null;
            return Promise.resolve({
                filename: contentDispositionHeader,
                blob: response.blob(),
            });
        });
    };
}

export function getRecord(duid) {
    return () => {
        const hashId = resolveHashIdFromState();

        return RestCaller.httpGetWithBinary(Resource.getRecordContent(duid, hashId));
    };
}

export function pregenerateRecord(fieldName, templateDefinitionHash) {
    return () => {
        const hashId = resolveHashIdFromState();

        return RestCaller.httpGetWithBinary(
            Resource.pregenerateRecord(fieldName, templateDefinitionHash, hashId),
        );
    };
}

export function loadDocuments(attrName, allowedRecordTypes, fieldName) {
    return (dispatch) => {
        console.log('Loading document:', attrName);
        const hashId = resolveHashIdFromState();

        return RestCaller.httpGet(Resource.loadDocuments(hashId, fieldName)).then(
            (result) => {
                const newValue =
                    isEmptyArray(allowedRecordTypes) || isEmptyArray(result)
                        ? result
                        : result.filter((item) => allowedRecordTypes.includes(item.documentType));
                return dispatch(fireAttrChange({ name: attrName, value: newValue, append: false }));
            },
            (error) => {
                console.log('request failed', error);
                return Promise.reject(error);
            },
        );
    };
}

const updateBopRecords = (records, record) => {
    const recordIndex = records?.findIndex((r) => r.conditionUuid === record.conditionUuid);
    records[recordIndex] = record;
    return [...records];
};

export function uploadConditionFiles(fieldName, attrName, files, filesSize, records, record) {
    return (dispatch) => {
        const request = new FormData();
        files.forEach((file) => request.append('file', file));

        const hashId = resolveHashIdFromState();
        return RestCaller.httpPostWithBinary(
            Resource.uploadDocument(hashId, fieldName),
            request,
        ).then(
            (result) => {
                //Check if we are adding first document to the array -> set statuses to IN_PROGRESS/UPLOADED
                if (record.documents && record.documents.length === 0) {
                    record.status = 'UPLOADED';
                }
                record.documents = [...record.documents, ...result];
                const updatedRecords = updateBopRecords(records, record);
                dispatch(fireAttrChange({ name: attrName, value: updatedRecords, append: false }));
            },
            (error) => {
                console.log('request failed', error);
                error.skipGlobalExceptionHandler = true; // Showing the error must be handled by the caller component.
                return Promise.reject(error);
            },
        );
    };
}

export function uploadFiles(fieldName, attrName, files) {
    return (dispatch) => {
        const request = new FormData();

        files.forEach((file) => request.append('file', file));

        const hashId = resolveHashIdFromState();
        return RestCaller.httpPostWithBinary(
            Resource.uploadDocument(hashId, fieldName),
            request,
        ).then(
            (result) => dispatch(fireAttrChange({ name: attrName, value: result, append: true })),
            (error) => {
                console.log('request failed', error);
                error.skipGlobalExceptionHandler = true; // Showing the error must be handled by the caller component.
                return Promise.reject(error);
            },
        );
    };
}

export function deleteConditionDocument(fieldName, attrName, document, records) {
    return (dispatch) => {
        console.log('Deleting document ' + document);
        const hashId = resolveHashIdFromState();
        return RestCaller.httpDelete(Resource.deleteDocument(fieldName, hashId, document)).then(
            () => dispatch(fireAttrChange({ name: attrName, value: records, append: false })),
            (error) => {
                console.log('deleting document ' + document + ' failed', error);
                return Promise.reject(error);
            },
        );
    };
}

export function deleteDocument(fieldName, attrName, document) {
    return (dispatch) => {
        console.log('Deleting document ' + document);
        const hashId = resolveHashIdFromState();
        return RestCaller.httpDelete(Resource.deleteDocument(fieldName, hashId, document)).then(
            () => dispatch(documentDeleted(attrName, document.documentId)),
            (error) => {
                console.log('deleting document ' + document + ' failed', error);
                return Promise.reject(error);
            },
        );
    };
}

export const extensionsMapper = {
    'image/jpeg': '.jpeg', //also for jfif / jfif-tbnl
    'image/png': '.png',
    'image/jpg': '.jpg',
    'image/jpe': '.jpe',
    'image/gif': '.gif',
    'application/pdf': '.pdf',
    'application/zip': '.zip',
    'application/x-zip-compressed': '.zip',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
    'application/vnd.ms-excel': '.xls',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
    'application/msword': '.doc',
    'application/vnd.ms-word.template.macroEnabled.12': '.dotm',
};

export function filterFiles(file, extensions) {
    return !!extensionsMapper[file.type] && extensions.includes(extensionsMapper[file.type]); //filtering files with unsupported extensions
}
