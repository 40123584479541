//import thunk from 'redux-thunk';
import createThunkErrorHandlerMiddleware from 'redux-thunk-error-handler';
import { showErrorMessage } from '../actions/taskActions';
import { getLocale } from './i18n';
import gtilogger from './loggly/gtiLogger';
import logger from 'redux-logger';
import { configureStore } from '@reduxjs/toolkit';
import categorizationReducers from '../reducers/categorization/categorizationReducers';
import taskReducers from '../reducers/task/taskReducers';
import templateReducers from '../reducers/template/templateReducers';
import rootReducers from '../reducers/root/rootReducers';
import userReducers from '../reducers/user/userReducers';
import gaReducers from '../reducers/ga/gaReducers';

const globalErrorHandler = (err) => {
    console.error('Global error handler: ' + err);

    const message = err.message;
    try {
        const jsonMessage = JSON.parse(message);
        if (jsonMessage.status === 403 || jsonMessage.status === '403') {
            if (jsonMessage.errors && jsonMessage.errors.length > 0) {
                const jsonError = jsonMessage.errors[0].error;
                if (jsonError === 'FILE_WAS_BLOCKED') {
                    return () => Promise.reject(jsonError);
                }
            }
            gtilogger.error(jsonMessage);
            window.location.href = import.meta.env.VITE_APP_LOGOUT_URL.replace(
                '${lang}',
                getLocale(),
            );
        } else {
            return (dispatch) => {
                gtilogger.error(jsonMessage.message);
                if (err.skipGlobalExceptionHandler) {
                    return () => Promise.reject(err);
                } else {
                    return dispatch(showErrorMessage(jsonMessage.message)).then(() =>
                        Promise.reject(err),
                    );
                }
            };
        }
    } catch (error) {
        if (err.skipGlobalExceptionHandler) {
            return () => Promise.reject(err);
        } else {
            return (dispatch) =>
                dispatch(showErrorMessage(message)).then(() => Promise.reject(err));
        }
    }
};

const errorHandlerMiddleware = createThunkErrorHandlerMiddleware({ onError: globalErrorHandler });

let middleware = [errorHandlerMiddleware];
// Middlewares for development env only
if (import.meta.env.DEV) {
    middleware = [...middleware, logger];
}

const store = configureStore({
    // Automatically calls `combineReducers`
    reducer: {
        categorization: categorizationReducers,
        root: rootReducers,
        task: taskReducers,
        templates: templateReducers,
        ga: gaReducers,
        user: userReducers,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false, // Due to validation functions being stored in the state.
            immutableCheck: false, // Due to attribute changes, before we migrate fully to redux-toolkit.
        }).concat(middleware),
});

/*
const store = createStore(
    rootReducers,
    applyMiddleware(...middleware)
);*/

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
