import { DeviceContext, DeviceContextProps } from '@csas-smart/gti-ui-comps';
import React, { useContext } from 'react';
import { movePrimaryToBegin, movePrimaryToEnd } from '../../common/reusableFunctions';
import SignButton from '../../comp/sign-button';
import ToggleButton from '../../comp/toggle-button';
import { ButtonGroup } from '@george-labs.com/design-system';

const FooterSignTogglegroup = ({
    buttons,
    onClick,
    btnWidth,
    isDisabled,
    editable,
    field,
    fieldValueChanged,
    completeActivityAction,
    validateActivityAction,
}) => {
    const deviceContext = useContext<DeviceContextProps>(DeviceContext);
    let options = [...buttons];
    options = options.sort(deviceContext.isMobile ? movePrimaryToBegin : movePrimaryToEnd);

    const generateSecondaryButton = (option) => {
        return (
            <ToggleButton
                option={option}
                onClick={onClick}
                width={btnWidth}
                disabled={isDisabled || !editable}
            />
        );
    };

    const generatePrimaryButton = (option) => {
        return (
            <SignButton
                field={field}
                option={option}
                onClick={onClick}
                width={btnWidth}
                disabled={isDisabled || !editable}
                fieldValueChanged={fieldValueChanged}
                completeActivityAction={completeActivityAction}
                validate={validateActivityAction}
            />
        );
    };

    return (
        <ButtonGroup>
            {options.map((option) => {
                if (option.buttonType === 'primary') {
                    return generatePrimaryButton(option);
                } else {
                    return generateSecondaryButton(option);
                }
            })}
        </ButtonGroup>
    );
};

export default FooterSignTogglegroup;
